import { Text, Tooltip } from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '@/context/Analytics';
import useElement from '@/hooks/useElement';
import useSession from '@/hooks/useSession';
import { useResource } from '@/lib/datx-jsonapi-react';
import { queryLiblynxAuthorizationGrants } from '@/queries/liblynx-authorization-grants';

export const InstitutionLabel: FC = () => {
  const { t } = useTranslation();
  const { user } = useSession();
  const isTrackedRef = useRef(false);

  const analytics = useAnalytics();

  const { element: grantLabelElement, ref: grantLabelRef } = useElement();

  const isOverflown =
    grantLabelElement?.offsetWidth < grantLabelElement?.scrollWidth ||
    grantLabelElement?.offsetHeight < grantLabelElement?.scrollHeight;

  const isPremium = user?.package === 'premium';

  const { data } = useResource(queryLiblynxAuthorizationGrants('auth_grant'), {
    onSuccess: () => {
      if (data?.liblynxOrganization && !isTrackedRef.current) {
        analytics.track('library_liblynx_access', {
          institution_name: data.liblynxOrganization,
        });
        isTrackedRef.current = true;
      }
    },
  });
  const isLiblynxGrantActive = data?.status === 'active';

  const grantLabel = data?.status === 'active' ? data?.liblynxOrganization : t('liblynx.institutionLabel.premium');

  if (!data || (!isLiblynxGrantActive && !isPremium)) {
    return null;
  }
  return (
    <Tooltip
      label={grantLabel}
      isDisabled={!isOverflown}
      bg="white"
      hasArrow
      maxW={{ base: 'xs', lg: '4xl' }}
      wordBreak="break-all"
    >
      <Text ref={grantLabelRef} fontSize="sm" noOfLines={1}>
        {grantLabel}
      </Text>
    </Tooltip>
  );
};
