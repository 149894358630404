import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Menu,
  MenuButtonProps,
  MenuItem,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import { OriginalsSeries } from '@/resources/OriginalsSeries';

interface ICoreNavigationMenuDropdownMobileProps extends MenuButtonProps {
  navigationTitle: string;
  navigationItems: Array<OriginalsSeries>;
}

export const OriginalsMenuDropdownMobile: FC<ICoreNavigationMenuDropdownMobileProps> = ({
  navigationTitle,
  navigationItems,
  ...rest
}) => {
  const router = useRouter();

  function getHref(original: OriginalsSeries) {
    return `/originals/${original.id}-${original.slug}`;
  }

  return (
    <Flex display={{ base: 'block', lg: 'none' }}>
      <Menu variant="navigation" colorScheme="blackAlpha" placement="bottom-end" {...rest}>
        <Accordion allowMultiple minWidth={292} border={0}>
          <AccordionItem border={0}>
            <h2>
              <AccordionButton
                border={0}
                fontWeight={700}
                px={{ base: '20px', md: '40px' }}
                py={3}
                pr={{ base: '28px', md: '20px' }}
              >
                <Box as="span" flex="1" textAlign="left" _hover={{ background: 'none', textDecoration: 'underline' }}>
                  {navigationTitle}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {navigationItems &&
                navigationItems.map((original: OriginalsSeries) => (
                  <NextLink href={getHref(original)} passHref key={original.id} legacyBehavior>
                    <NavLink
                      as={MenuItem}
                      isActiveLink={router.asPath.startsWith(getHref(original))}
                      _hover={{ background: 'none', textDecoration: 'underline' }}
                    >
                      {original.author.firstName} {original.author.lastName}
                    </NavLink>
                  </NextLink>
                ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Menu>
    </Flex>
  );
};
