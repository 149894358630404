import { useResources } from 'datx-jsonapi-react';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { OriginalsMenuDropdownDesktop } from '@/components/ui/shared/headers/OriginalsMenuDropdown/OriginalsMenuDropdown.desktop';
import { OriginalsMenuDropdownMobile } from '@/components/ui/shared/headers/OriginalsMenuDropdown/OriginalsMenuDropdown.mobile';
import { queryAllOriginalsSeries } from '@/queries/originals-series';

export const OriginalsMenu: FC = () => {
  const { t } = useTranslation();
  const { data } = useResources(queryAllOriginalsSeries({ collection: 'false' }));

  return (
    <Fragment>
      <OriginalsMenuDropdownDesktop navigationTitle={t('navigation.originals')} navigationItems={data} />
      <OriginalsMenuDropdownMobile navigationTitle={t('navigation.originals')} navigationItems={data} />
    </Fragment>
  );
};
