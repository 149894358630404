import { Flex, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { NavLink } from '@/components/ui/shared/links/NavLink/NavLink';
import { OriginalsSeries } from '@/resources/OriginalsSeries';

interface ICoreNavigationMenuDropdownDesktopProps extends MenuButtonProps {
  navigationTitle: string;
  navigationItems: Array<OriginalsSeries>;
}

export const OriginalsMenuDropdownDesktop: FC<ICoreNavigationMenuDropdownDesktopProps> = ({
  navigationTitle,
  navigationItems,
  ...rest
}) => {
  const router = useRouter();

  function getHref(original: OriginalsSeries) {
    return `/originals/${original.id}-${original.slug}`;
  }

  return (
    <Flex display={{ base: 'none', lg: 'block' }}>
      <Menu computePositionOnMount variant="navigation" colorScheme="blackAlpha" placement="bottom-end" {...rest}>
        <MenuButton
          boxShadow={0}
          border={0}
          borderRadius={0}
          fontSize={{ base: '16px' }}
          fontWeight={700}
          px={4}
          py={4}
          _hover={{ textDecoration: 'underline' }}
          _focus={{ boxShadow: 'none' }}
        >
          {navigationTitle}
        </MenuButton>

        <MenuList color="white">
          {navigationItems &&
            navigationItems?.map((original: OriginalsSeries) => (
              <NextLink href={getHref(original)} passHref key={original.id} legacyBehavior>
                <MenuItem as={NavLink} isActiveLink={router.asPath.startsWith(getHref(original))}>
                  {original.author.firstName} {original.author.lastName}
                </MenuItem>
              </NextLink>
            ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};
