import { Box, Flex } from '@chakra-ui/react';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKey } from 'react-use';

import { AdminAlerts } from '@/components/AdminAlerts';
import { FadeInModal } from '@/components/FadeInModal';
import { FadeInModalContext } from '@/components/layouts/fadeInModalContext';
import { Footer } from '@/components/ui/shared/footer/CommonFooter/Footer';
import { CoreHeader } from '@/components/ui/shared/headers/CoreHeader/CoreHeader';
import { ILayoutProps } from '@/components/ui/shared/layouts/Layout/Layout';
import { LibLynxCounterDefaults } from '@/components/ui/shared/utilities/LibLynxCounterDefaults/LibLynxCoutnerDefaults';
import { ThankYou } from '@/containers/ContactUs/ThankYou';
import { PasswordReset } from '@/containers/PasswordReset';
import { useAnalytics } from '@/context/Analytics';
import { AccessFrom } from '@/enums/AccessFrom';
import { OverlayType } from '@/enums/index';
import { IToastType } from '@/enums/ToastType';
import { useBanners } from '@/hooks/useBanners';
import { useLogoutMutation } from '@/hooks/useLogoutMutation';
import useModalName from '@/hooks/useModalName';
import { useToast } from '@/hooks/useToast';

export const CoreLayout: FC<ILayoutProps> = ({ children, displayFooter = true, beforeChildren, navigationVariant }) => {
  const { t } = useTranslation();
  const modalName = useModalName();
  const banners = useBanners();
  const toast = useToast();
  const analytics = useAnalytics();

  const [showModalType, setShowModalType] = useState(modalName || OverlayType.NONE);

  const onLogoutSuccess = () => {
    analytics.track('user_logout');
    toast({ status: IToastType.SUCCESS, title: t('action.loggedOut') });
  };

  const onLogoutError = () => {
    toast({ status: IToastType.ERROR, title: t('action.loggedOutError') });
  };

  const [logOut] = useLogoutMutation({ onSuccess: onLogoutSuccess, onFailure: onLogoutError });

  useKey('Escape', () => setShowModalType(OverlayType.NONE));

  const isOverlayVisible = OverlayType.RESET_PASSWORD === showModalType || OverlayType.CONTACT_THANKS === showModalType;

  return (
    <Fragment>
      <LibLynxCounterDefaults />
      <FadeInModalContext.Provider
        value={{
          showModalType: { get: showModalType, set: setShowModalType },
        }}
      >
        <FadeInModal showModal={showModalType === OverlayType.RESET_PASSWORD}>
          <PasswordReset />
        </FadeInModal>
        <FadeInModal showModal={showModalType === OverlayType.CONTACT_THANKS}>
          <ThankYou />
        </FadeInModal>

        <CoreHeader accessFrom={AccessFrom.Core} variant={navigationVariant} onLogout={() => logOut(null)} />

        <Flex as="main" flexDir="column" minH="100vh" filter={isOverlayVisible ? 'blur(40px) brightness(50%)' : 'none'}>
          {beforeChildren && beforeChildren}
          <Box position="relative" flex={1}>
            {children}
          </Box>
        </Flex>

        {displayFooter && <Footer />}
        {banners && <AdminAlerts banners={banners} />}
      </FadeInModalContext.Provider>
    </Fragment>
  );
};
