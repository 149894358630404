import { Box, Container, Flex, Grid, Heading, Icon, Link, ListItem, UnorderedList } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LinkedinIcon from '@/assets/icons/ic-share-linkedin-circle.svg';
import TwitterIcon from '@/assets/icons/ic-share-twitter-circle.svg';
import YoutubeIcon from '@/assets/icons/ic-share-youtube-circle.svg';

import { NewsletterSubscription } from '@/components/ui/shared/footer/NewsletterSubscription/NewsletterSubscription';
import { useAnalytics } from '@/context/Analytics';
import { AccessFrom } from '@/enums/AccessFrom';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { MultiselectDropdown } from '@/components/ui/core/MultiselectDropdown/MultiselectDropdown';

const FooterListItem = ({ children }) => {
  return (
    <ListItem
      textTransform="capitalize"
      fontSize={{ base: '16px', md: '14px' }}
      textDecoration="none"
      _hover={{ textDecoration: 'underline' }}
      _last={{ mb: 0 }}
      fontWeight="medium"
      mb={4}
    >
      {children}
    </ListItem>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const { accessFrom = AccessFrom.Core } = useQueryParams<{ accessFrom: AccessFrom }>({
    accessFrom: StringParam,
  });

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (url === '/contact-us/support') {
        analytics.track('contact_us_click', { button_text: t('noTicket.buttonLabel') });
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, analytics, t]);

  return (
    <Container lineHeight="20px" mt={{ base: 8, md: 16, lg: 32 }}>
      <NewsletterSubscription mb="20" />
      <Grid
        gridColumnGap={6}
        pb={16}
        color="black"
        gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: `repeat(4, 1fr)` }}
        gridTemplateRows={{ base: `repeat(2, 1fr)`, md: '1fr' }}
        gridRowGap={14}
        fontFamily="body"
      >
        <Box>
          <Heading fontFamily="body" fontWeight="semibold" mb={6} fontSize="14px" lineHeight="18px" color="gray.600">
            {t('footer.presentations')}
          </Heading>
          <UnorderedList ml="0" listStyleType="none">
            <FooterListItem>
              <NextLink href="/library" passHref>
                {t('footer.allLectures')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/librarians" passHref>
                {t('footer.informationForLibrarians')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/librarians-resource" passHref>
                {t('footer.librariansResourceCenter')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="https://underline-science.paperform.co/?utm_source=footer&utm_medium=website" passHref>
                {t('footer.freeTrial')}
              </NextLink>
            </FooterListItem>
          </UnorderedList>
        </Box>
        <Box>
          <Heading fontFamily="body" fontWeight="semibold" mb={6} fontSize="14px" lineHeight="18px" color="gray.600">
            {t('footer.conferences')}
          </Heading>
          <UnorderedList ml="0" listStyleType="none">
            <FooterListItem>
              <NextLink href={`/events-list?status=live&accessFrom=${accessFrom}`} passHref>
                {t('footer.currentConferences')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href={`/events-list?status=upcoming&accessFrom=${accessFrom}`} passHref>
                {t('footer.futureConferences')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/events" passHref>
                {t('footer.forConferenceOrganizers')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/news?articleTopicId=2" passHref>
                {t('footer.conferenceNews')}
              </NextLink>
            </FooterListItem>
          </UnorderedList>
        </Box>
        <Box>
          <Heading fontFamily="body" fontWeight="semibold" mb={6} fontSize="14px" lineHeight="18px" color="gray.600">
            {t('footer.company')}
          </Heading>
          <UnorderedList ml="0" listStyleType="none">
            <FooterListItem>
              <NextLink href="/about" passHref>
                {t('footer.about')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/team" passHref>
                {t('footer.team')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/partners" passHref>
                {t('footer.partners')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/privacy-policy" passHref>
                {t('footer.privacyPolicy')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/terms-of-use" passHref>
                {t('footer.termsOfUse')}
              </NextLink>
            </FooterListItem>
          </UnorderedList>
        </Box>
        <Box>
          <Heading fontFamily="body" fontWeight="semibold" mb={6} fontSize="14px" lineHeight="18px" color="gray.600">
            {t('footer.resources')}
          </Heading>
          <UnorderedList ml="0" listStyleType="none">
            <FooterListItem>
              <NextLink href="/news?articleTopicId=8" passHref>
                {t('footer.blog')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/contact-us/press-inquiries" passHref>
                {t('footer.press')}
              </NextLink>
            </FooterListItem>
            <FooterListItem>
              <NextLink href="/contact-us/support" passHref>
                {t('footer.contactUs')}
              </NextLink>
            </FooterListItem>
          </UnorderedList>
        </Box>
      </Grid>
      <Flex fontFamily="body" mb={20} justify="space-between" flexDir={{ base: 'column', lg: 'row' }}>
        <Box fontSize="sm" color="gray.600">
          Underline Science, Inc.
          <br />
          1216 Broadway, 2nd Floor, New York, NY 10001, USA
          <p>&copy; 2023 Underline - All rights reserved</p>
        </Box>
        <Box>
          <Link aria-label="Visit our linkedin" href="https://www.linkedin.com/company/underline-science-inc">
            <Icon boxSize="30px" as={LinkedinIcon} />
          </Link>
          <Link aria-label="Visit our twitter" mx={4} href="https://twitter.com/underlineio">
            <Icon boxSize="30px" as={TwitterIcon} />
          </Link>
          <Link aria-label="Visit our youtube" href="https://www.youtube.com/@underlinescience">
            <Icon boxSize="30px" as={YoutubeIcon} />
          </Link>
        </Box>
      </Flex>
    </Container>
  );
};
