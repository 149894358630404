import { QueryResource, QueryResources } from 'datx-jsonapi-react';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { OriginalsSeries } from '@/resources/OriginalsSeries';

export const getOriginalSeries = (id: string): QueryResource<OriginalsSeries> => {
  return id ? [OriginalsSeries, id, { include: ['subject', 'author'] }] : null;
};
export const queryAllOriginalsSeries = (filters = {}): QueryResources<OriginalsSeries> => {
  return [
    OriginalsSeries,
    {
      filter: {
        ...omitBy(filters, isNil),
      },
      sort: 'created_at',
      include: ['author'],
      loadAll: true,
    },
  ];
};
