import { useState, useCallback } from 'react';

interface IUseElement<T> {
  element: T | null;
  ref: (element: T) => void;
}

/**
 * Hook allows you to lazily evaluate an element and receive re-render
 * each time the element changes.
 *
 * @returns element that can be used for calculations and ref that should
 * be applied to the node that you are interested at.
 */

/**
 * @deprecated You don't want to save ref in the state because it will trigger rerender.
 * You can't treat the DOM node as a reactive value, it's owned by the browser, not React.
 */
function useElement<T extends HTMLElement>(): IUseElement<T> {
  const [element, setElement] = useState<T>(null);
  const ref = useCallback((newElement) => {
    setElement(newElement);
  }, []);

  return { element, ref };
}

export default useElement;
